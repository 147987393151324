
import { defineComponent, onMounted, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiContacts, ApiMerchant, ApiBase } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import _ from "lodash";
import mixin from "@/mixins";
import { commonChangeDefaultDate } from "@/core/directive/function/common";

export default defineComponent({
  name: "add-contacts-modal",
  components: {},
  emits: ["update-list"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const addStoresModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      salutation: "",
      firstname: "",
      lastname: "",
      email: "",
      merchant_id: "",
      position: "",
      phone: "",
      mobile: "",
      report_to: "",
      department: "",
      decision_level: "",
      wechat: "",
      whats_app: "",
      fax: "",
      private_phone: "",
      other_phone: "",
      other_phone_notice: "",
      birthday: "",
      assistant: "",
      assistant_email: "",
      assistant_phone: "",
      overall_notes: "",
      is_fulfillment_order_contact: 0,
    });

    const validatePassPhone = (rule: any, value: any, callback: any) => {
      let { email, mobile, wechat } = formRef.value?.model;
      console.log(1);
      if (value === "" && email == "" && mobile == "" && wechat == "") {
        callback(
          new Error("Phone or Email or Mobile or WeChat Account is required")
        );
        // formRef.value?.validateField("email");
        // if (email == "") formRef.value?.validateField("email");
      } else {
        formRef.value?.clearValidate(["email", "mobile", "wechat"]);
        callback();
      }
    };

    const validatePassEmail = (rule: any, value: any, callback: any) => {
      let { phone, mobile, wechat } = formRef.value?.model;
      console.log(2);
      if (value === "" && phone == "" && mobile == "" && wechat == "") {
        callback(
          new Error("Phone or Email or Mobile or WeChat Account is required")
        );
        // if (phone == "") formRef.value?.validateField("phone");
      } else {
        formRef.value?.clearValidate(["phone", "mobile", "wechat"]);
        callback();
      }
    };

    const validatePassMobile = (rule: any, value: any, callback: any) => {
      let { email, phone, wechat } = formRef.value?.model;
      if (value === "" && email == "" && phone == "" && wechat == "") {
        callback(
          new Error("Phone or Email or Mobile or WeChat Account is required")
        );
      } else {
        formRef.value?.clearValidate(["email", "phone", "wechat"]);
        callback();
      }
    };

    const validatePassWechat = (rule: any, value: any, callback: any) => {
      let { email, mobile, phone } = formRef.value?.model;
      if (value === "" && email == "" && mobile == "" && phone == "") {
        callback(
          new Error("Phone or Email or Mobile or WeChat Account is required")
        );
      } else {
        formRef.value?.clearValidate(["email", "mobile", "phone"]);
        // formRef.value?.validateField("email");
        // formRef.value?.validateField("mobile");
        // formRef.value?.validateField("phone");
        callback();
      }
    };

    const rules = ref({
      salutation: [
        {
          required: true,
          message: "Salutation is required",
          trigger: "change",
        },
      ],
      firstname: [
        {
          required: true,
          message: "First Name is required",
          trigger: "change",
        },
      ],
      lastname: [
        {
          required: true,
          message: "Last Name is required",
          trigger: "change",
        },
      ],
      merchant_id: [
        {
          required: true,
          message: "Parent Merchant is required",
          trigger: "change",
        },
      ],
      position: [
        {
          required: true,
          message: "Position is required",
          trigger: "change",
        },
      ],
      phone: [
        {
          validator: validatePassPhone,
          trigger: "change",
        },
      ],
      email: [
        {
          validator: validatePassEmail,
          trigger: "change",
        },
      ],
      mobile: [
        {
          validator: validatePassMobile,
          trigger: "change",
        },
      ],
      wechat: [
        {
          validator: validatePassWechat,
          trigger: "change",
        },
      ],
    });

    /******************************** 
      获取下拉框数据 
      merchant_id 商户
      country_iso_2国家
    *********************************/
    const options = ref({
      merchant_id: [],
      position: [] as any[],
      report_to: [],
      department: [] as any[],
      departmentLoading: false,
      departmentLoad: false,
      decision_level: [],
      positionLoading: false,
      positionLoad: false,
    });

    const getCountryOptions = () => {
      ApiContacts.getContactData()
        .then(({ data }) => {
          if (data.code == 0) {
            options.value.report_to = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const tagging = ref({
      department: {},
      position: {},
      decision_level: {},
    });

    const querySearchAsync = async (
      queryString: string,
      cb: (arg: any) => void
    ) => {
      if (options.value.positionLoading) return cb([]);
      if (options.value.positionLoad) {
        let filterArr: any[] = options.value.position.filter(
          (item) =>
            item.value.toUpperCase().indexOf(queryString.toUpperCase()) > -1
        );
        cb(filterArr);
        return false;
      }
      options.value.positionLoading = true;
      const { data } = await ApiContacts.getPositionData({});
      options.value.positionLoading = false;
      if (data.code == 0) {
        let filterArr: any[] = data.data.filter(
          (item) =>
            item.value.toUpperCase().indexOf(queryString.toUpperCase()) > -1
        );
        cb(filterArr);
        options.value.position = [...data.data];
        options.value.positionLoad = true;
      }
    };

    const querySearchAsyncDepartment = async (
      queryString: string,
      cb: (arg: any) => void
    ) => {
      if (options.value.departmentLoading) return cb([]);
      if (options.value.departmentLoad) {
        let filterArr: any[] = options.value.department.filter(
          (item) =>
            item.value.toUpperCase().indexOf(queryString.toUpperCase()) > -1
        );
        cb(filterArr);
        return false;
      }
      options.value.departmentLoading = true;
      const { data } = await ApiContacts.getDepartmentData({});
      options.value.departmentLoading = false;
      if (data.code == 0) {
        let filterArr: any[] = data.data.filter(
          (item) =>
            item.value.toUpperCase().indexOf(queryString.toUpperCase()) > -1
        );
        cb(filterArr);
        options.value.department = [...data.data];
        options.value.departmentLoad = true;
      }
    };

    const getMerchantSourceData = async (value, id?) => {
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData(params);
      if (data.code == 0) {
        options.value.merchant_id = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const getCompanyOptions = () => {
      // "global_department",
      ApiBase.getTaggingData({
        short_key: ["global_decision_level"],
      })
        .then(({ data }) => {
          if (data.code == 0) {
            // tagging.value.department = data.data.global_department;
            // tagging.value.position = data.data.global_position;
            tagging.value.decision_level = data.data.global_decision_level;
            // options.value.department = data.data.global_department.items;
            // options.value.position = data.data.global_position.items;
            options.value.decision_level =
              data.data.global_decision_level.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    /******************************** 
      获取下拉框数据 end
    *********************************/

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiContacts.addContact(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addStoresModalRef.value);
                  emit("update-list");
                  options.value.positionLoad = false;
                  options.value.departmentLoad = false;
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(addStoresModalRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    const init = () => {
      getCompanyOptions();
      getCountryOptions();
    };

    onMounted(() => {
      init();
    });

    return {
      commonChangeDefaultDate,
      loading,
      formData,
      options,
      rules,
      formRef,
      addStoresModalRef,
      submitButton,
      querySearchAsync,
      querySearchAsyncDepartment,
      submit,
      handleDiscard,
      resetForm,
      tagging,
      searchMerchantSourceItems,
    };
  },
});
