
import { defineComponent, ref, onMounted } from "vue";
import { ApiBase, ApiMerchant } from "@/core/api";
import _ from "lodash";
interface FilterData {
  salutation: string;
  firstname: string;
  lastname: string;
  merchant_id: string;
}
export default defineComponent({
  name: "contact-filter-dropdown",
  components: {},
  emits: ["update-list"],
  props: {
    // filterGroup: {
    //   type: Object,
    //   required: true,
    // },
  },
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    /******************************** 
      获取下拉框数据 
      merchant_id 商户
    *********************************/
    const options = ref({
      merchant_id: [],
    });
    const getMerchantSourceData = async (value, id?) => {
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData(params);
      if (data.code == 0) {
        options.value.merchant_id = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    /******************************** 
     data 克隆数据
    *********************************/
    const data = ref<FilterData>({
      salutation: "",
      firstname: "",
      lastname: "",
      merchant_id: "",
    });
    const submit = () => {
      emit("update-list", data);
    };
    const resetData = () => {
      formRef.value?.resetFields();
      emit("update-list", data);
    };

    return {
      data,
      formRef,
      options,
      submit,
      resetData,
      searchMerchantSourceItems,
    };
  },
});
