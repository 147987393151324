
import { defineComponent, ref, onMounted } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import addStoresModal from "@/views/contacts/AddContactsModal.vue";
import FilterDropdwon from "@/views/contacts/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiContacts } from "@/core/api";
import _ from "lodash";
import fileDownload from "js-file-download";
import { setModuleBCN } from "@/core/directive/function/common";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

interface Contacts {
  salutation: string;
  firstname: string;
  lastname: string;
  merchant: number;
  position: number;
  email: string;
  phone: string;
  mobile: string;
}

interface SortOrders {
  field: string;
  direction: string;
}

interface Filter {
  field: string;
  value: string;
  from?: string;
  to?: string;
  condition?: string;
}

export default defineComponent({
  name: "contacts",
  components: {
    MBDatatable,
    addStoresModal,
    FilterDropdwon,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<Contacts>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const disabledExport = ref(false);
    const tableHeader = ref([
      {
        name: "id",
        key: "id",
        sortable: true,
      },
      {
        name: "Position",
        key: "position",
        sortable: true,
      },
      {
        name: "Salutation",
        key: "salutation",
        sortable: true,
      },
      {
        name: "First Name",
        key: "firstname",
        sortable: true,
      },
      {
        name: "Last Name",
        key: "lastname",
        sortable: true,
      },
      {
        name: "Merchant Company",
        key: "merchant_id",
        sortable: true,
      },
      {
        name: t("common.action"),
        key: "actions",
      },
    ]);
    const sortOrders = ref<Array<SortOrders>>([
      {
        field: "id",
        direction: "desc", // desc && asc
      },
    ]);

    const filterGroup = ref<Array<Filter>>([
      {
        field: "salutation",
        value: "",
      },
      {
        field: "firstname",
        value: "",
        condition: "LIKE",
      },
      {
        field: "lastname",
        value: "",
        condition: "LIKE",
      },
      {
        field: "merchant_id",
        value: "",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getContactList(
        currentPage.value,
        pageSize.value,
        sortOrders.value,
        filterGroup.value,
        search.value
      );
    });

    const getContactList = (
      page: number,
      pageSize: number,
      sortOrders?: Array<SortOrders>,
      filterGroup?: Array<Filter>,
      search?: string
    ) => {
      loading.value = true;
      let data = filterGroup?.filter((item) => {
        return item.value != "";
      });
      ApiContacts.getContactList({
        page: page,
        page_size: pageSize,
        sort_orders: sortOrders,
        filter_group: data,
        search_value: search,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getContactList(
        page,
        pageSize.value,
        sortOrders.value,
        filterGroup.value,
        search.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getContactList(
        currentPage.value,
        pagesize,
        sortOrders.value,
        filterGroup.value,
        search.value
      );
    };

    const onFilter = (data) => {
      let filterGroupData = JSON.parse(JSON.stringify(data.value));
      // filterGroupData.forEach((element) => {
      //   console.log(element);
      // });
      assemblyFilter(filterGroupData);
      getContactList(
        currentPage.value,
        pageSize.value,
        sortOrders.value,
        filterGroup.value,
        search.value
      );
    };

    const assemblyFilter = (data) => {
      filterGroup.value.forEach((item) => {
        console.log(item);
        item.value = data[item.field];
      });
    };

    const search = ref<string>("");
    const debounceSearch = _.debounce(getContactList, 1000);

    const searchItems = () => {
      debounceSearch(
        currentPage.value,
        pageSize.value,
        sortOrders.value,
        filterGroup.value,
        search.value
      );
    };

    const renew = () => {
      getContactList(
        currentPage.value,
        pageSize.value,
        sortOrders.value,
        filterGroup.value,
        search.value
      );
    };

    const onColumnSort = (val) => {
      let sortObj: SortOrders = {
        field: val.columnName,
        direction: val.order,
      };
      sortOrders.value.splice(0, sortOrders.value.length, sortObj);
      getContactList(
        currentPage.value,
        pageSize.value,
        sortOrders.value,
        filterGroup.value,
        search.value
      );
    };

    const fileExport = () => {
      disabledExport.value = true;
      ApiContacts.contactPrint({
        search_value: search.value,
        filter_group: filterGroup.value,
        sort_orders: sortOrders.value,
        download_data_type: "stream",
      })
        .then((data) => {
          disabledExport.value = false;
          const disposition = data.headers["content-disposition"];
          fileDownload(data.data, disposition.split("filename=")[1]);
        })
        .catch((error) => {
          disabledExport.value = false;
          console.log(error);
        });
    };

    return {
      loading,
      tableHeader,
      tableData,
      sortOrders,
      filterGroup,
      onFilter,
      currentPage,
      total,
      pageSize,
      search,
      getContactList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      onColumnSort,
      renew,
      disabledExport,
      fileExport,
    };
  },
});
